
import {
  Link,
  Element
} from "react-scroll";
import { images } from "./Images";
import { useMediaQuery } from "react-responsive";
import CommonButton from "./CommonButton";
import { MdArrowCircleDown } from 'react-icons/md';
import { useEffect } from "react";
import CampsItem from "./CampsItem";
import CampsDate from "./CampsDate";
import CampsImage from "./CampsImage";
import PageHeader from "./PageHeader";
import Underline from "./Underline";
import PensionBox from "./PensionBox";
import UnderlineSmall from "./UnderlineSmall";
import { FaFilePdf } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function Camps() {

  const lg = useMediaQuery({ query: '(max-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  useEffect(() => {
    const inViewport = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(inViewport);
    const element = document.querySelectorAll('[data-inviewport]');
    element.forEach(e => observer.observe(e));
  }, []);

  return <>
    <div className="flex flex-col">
      <PageHeader
        title={<>Camp Équestre</>}
        subtitle={<>Des chevaux, des activités amusantes et des souvenirs mémorables au camp à l'écurie.</>}
      />
      <Element name="section-01" className="w-screen min-h-screen bg-stone-700 text-stone-300 relative snap-start p-4 flex flex-row">
        <div className="w-full portrait:w-4"></div>
        <div className="flex flex-col grow mt-2 lg:mt-8 landscape:max-w-6xl">
          <div className="text-3xl lg:text-6xl font-extrabold">Camp équestre</div>
          <div className="text-md lg:text-2xl font-light pt-2">Semaines de pur bonheur à l'écurie pour les enfants</div>
          <Underline />
          <div className="flex flex-col grow r h-full mb-20">
            <p className="text-lg font-light">
              Offrez à vos enfants une ou plusieurs semaines d'aventures équestres à notre camp qui est offert durant l'été à l'écurie !
              Nos moniteurs passionnés guideront vos enfants dans l'apprentissage de l'équitation et du comportement des chevaux,
              tout en s'amusant avec des activités éducatives et passionnantes. Les enfants apprendront à brosser, seller et monter les chevaux,
              ils auront également la chance de participer à des jeux et des activités en plein air.
            </p>
            <p className="text-lg font-light mt-5">
              Notre camp équestre offre une occasion unique à vos enfants de se rapprocher des chevaux et de la nature,
              tout en créant des souvenirs mémorables avec de nouveaux amis.</p>

            <h4 className="mt-12 text-2xl  decoration-amber-600">Au Programme</h4>
            <UnderlineSmall />
            <div className="flex flex-col items-start">
              <CampsItem icon={<>💡</>} text={<>Tout apprendre sur les chevaux</>} />
              <CampsItem icon={<>🏇</>} text={<>Un cours d'équitation par jour</>} />
              <CampsItem icon={<>🐎</>} text={<>Monter à cheval avec et sans selle</>} />
              <CampsItem icon={<>🐴</>} text={<>Activités à la ferme et avec les chevaux</>} />
              <CampsItem icon={<>🦄</>} text={<>Créer un lien avec son cheval</>} />
              <CampsItem icon={<>🌳</>} text={<>Découvrir la nature en balade à cheval</>} />
              <CampsItem icon={<>🐔</>} text={<>S'occuper du soin des animaux</>} />
              <CampsItem icon={<>🧼</>} text={<>Brosser, doucher nos chevaux</>} />
              <CampsItem icon={<>👫</>} text={<>Se faire de nouveaux amis</>} />
              <CampsItem icon={<>🤽‍♂️</>} text={<>Jeux d'eau pour les journées chaude</>} />
              <CampsItem icon={<>😁</>} text={<>Avoir beaucoup de plaisir à tous les jours!!</>} />
            </div>
          </div>
        </div>
        <div className="w-full portrait:w-4"></div>
      </Element>
      <Element name="section-02" className="w-screen min-h-screen-more bg-stone-100 relative snap-start p-4 flex flex-row pb-24">
        <div className="w-36 portrait:w-4"></div>
        <div className="flex flex-col grow mt-20">
          <div className="text-3xl lg:text-4xl font-extrabold">Un Avant-Goût</div>
          <div className="text-md lg:text-2xl font-light pt-2">Pour plus de photos, consultez notre page Facebook.</div>
          <Underline />
          <div className="flex flex-col flex-wrap lg:flex-row landscape:flex-row grow">
            <CampsImage img={images.camps_01} idx={0} />
            <CampsImage img={images.camps_02} idx={1} />
            <CampsImage img={images.camps_03} idx={2} />
            <CampsImage img={images.camps_04} idx={3} />
            <CampsImage img={images.camps_05} idx={4} />
            <CampsImage img={images.camps_06} idx={5} />
          </div>
        </div>
      </Element>
      {/* <div className="bg-stone-700 h-64 flex flex-row items-center justify-center text-2xl text-stone-100 font-light">
        Instructeur et entraîneur certifié sur place. Certifié par cheval Québec et Canada Équestre
      </div> */}

      <Element name="section-03" className="w-screen min-h-screen bg-stone-700 text-stone-50 relative snap-start p-4 flex flex-row">
        <div className="w-full portrait:w-4"></div>
        <div className="flex flex-col grow mt-20 w-full">
          <div className="text-3xl lg:text-4xl font-extrabold">Été 2025</div>
          {/* <div className="text-md lg:text-2xl font-light pt-2">Dates et prix à venir pour l'été 2025</div> */}
          <Underline />

          {/* <p className="mt-20  font-light text-3xl">Nous avous eu un super été 2025, merci à tous les participants! On se voit à l'été 2025.</p> */}
          <div className="flex flex-row items-start justify-start">
          <div className="mr-5 h-full">
              <PensionBox
                title={<>Promotion 2025</>}
                price={<>375$ par semaine</>}
                access={<>Valide jusqu'au 19 janvier 2025</>}
              />
            </div>
            <div className="mr-5 h-full">
              <PensionBox
                title={<>Camp Équestre</>}
                price={<>410$ par semaine</>}
                access={<>Durée: 1 semaine</>}
              />
            </div>
            <PensionBox
              title={<>Service de Garde</>}
              price={<>50$ par semaine</>}
              access={<span>Matin de 7h à 8h<br />Soir de 16h à 17h30</span>}
            />
          </div>
          <div className="grid grid-cols-3 gap-3 mt-12 w-108 portrait:w-full">
            <CampsDate week={<>Semaine 1</>} from={<>23 juin 2025</>} to={<>27 juin 2025</>} />
            <CampsDate week={<>Semaine 2</>} from={<>30 juin 2025</>} to={<>04 juillet 2025</>} />
            <CampsDate week={<>Semaine 3</>} from={<>07 juillet 2025</>} to={<>11 juillet 2025</>} />
            <CampsDate week={<>Semaine 4</>} from={<>14 juillet 2025</>} to={<>18 juillet 2025</>} />
            <CampsDate week={<>Semaine 5</>} from={<>04 août 2025</>} to={<>08 août 2025</>} />
            <CampsDate week={<>Semaine 6</>} from={<>11 août 2025</>} to={<>15 août 2025</>} />
            <CampsDate week={<>Semaine 7</>} from={<>18 août 2025</>} to={<>22 août 2025</>} />
            {/* <CampsDate week={<>Semaine 7</>} from={<>19 août 2025</>} to={<>23 août 2025</>} full /> */}
          </div>
          <p className="mt-10 mb-5 italic font-light">Les places disponibles sont limitées. Vous pouvez compléter le formulaire d'inscription et nous le faire parvenir par courriel.</p>
          <a href="mailto:info@ecuriechevalex.com" className="mb-2 ">
          <div className="flex flex-row "><MdEmail  className=" text-white" />
              <span className=" ml-2 text-white">info@ecuriechevalex.com</span>
            </div>
          </a>
          <a href="./Formulaire_Inscription_Camp_equestre2025.pdf" download="Formulaire_Inscription_Camp_equestre2025.pdf">
            <div className="flex flex-row "><FaFilePdf className=" text-red-600" />
              <span className=" ml-2 text-blue-400">Télécharger le formulaire d'inscription</span>
            </div>
          </a>
          <p className="mt-10 mb-5 italic font-light">Un dépôt de 100$ par semaines de camp réservées est requis afin de confirmer la place de votre enfant.</p>
        </div>

        <div className="w-full portrait:w-4"></div>
      </Element>


    </div>
  </>
}
