
import {
  Element} from "react-scroll";
import CourseThumbnail from "./CourseThumbnail";
import { images } from "./Images";
import CourseFormation from "./CourseFormation";
import CampsItem from "./CampsItem";
import PageHeader from "./PageHeader";
import Underline from "./Underline";
import UnderlineSmall from "./UnderlineSmall";

export default function Course() {

  return <>
    <div className="flex flex-col">
      <PageHeader
        title={<>Cours Équitation Western</>}
        subtitle={<>Notre centre équestre offre des cours en équitation western pour tous les niveaux. </>}
      />

      <Element name="section-01" className="w-screen min-h-screen bg-stone-700 text-stone-300 relative snap-start p-4 flex flex-row">
        <div className="w-full portrait:w-4"></div>
        <div className="flex flex-col grow mt-2 lg:mt-8 landscape:max-w-6xl">
          <div className="text-3xl lg:text-6xl font-extrabold">Cours offerts pour tous les niveaux</div>
          <div className="text-md lg:text-2xl font-light pt-2">Jamais monté à cheval ou plusieurs années d'expérience, nos cours sont adaptés pour vous.</div>
          <Underline/>
          <div className="flex flex-col lg:flex-row landscape:flex-row grow landscape:items-center justify-evenly h-full">
            <CourseThumbnail img={images.course_thumbail_01} label="Débutant" />
            <CourseThumbnail img={images.course_thumbail_02} label="Intermédiaire" />
            <CourseThumbnail img={images.course_thumbail_03} label="Avancé" />
          </div>
          {/* <img src={images.foliage_01.src} alt={images.foliage_01.alt} className="absolute top-0 left-0 opacity-100 w-24 lg:w-44 rotate-180 -scale-x-100" />
          <img src={images.foliage_01.src} alt={images.foliage_01.alt} className="absolute top-0 right-0 opacity-100  w-24 lg:w-44 rotate-180" />
          <img src={images.foliage_01.src} alt={images.foliage_01.alt} className="absolute bottom-0 left-0 opacity-100  w-24 lg:w-44" />
          <img src={images.foliage_01.src} alt={images.foliage_01.alt} className="absolute bottom-0 right-0 opacity-100  w-24 lg:w-44 -scale-x-100" /> */}
          <h4 className="mt-12 text-2xl ">Nos Installations</h4>
          <UnderlineSmall/>
          <div className="flex flex-col items-start">
            <CampsItem icon={<>🎪</>} text={<>Manège intérieur</>} />
            <CampsItem icon={<>🌞</>} text={<>Grand manège extérieur (Éclairé)</>} />
            <CampsItem icon={<>🐎</>} text={<>Rond de Longe ("Round Pen")</>} />
            <CampsItem icon={<>🌳</>} text={<>Sentiers pour randonnées</>} />
            <CampsItem icon={<>🔥</>} text={<>Salon (chauffé l'hiver)</>} />
          </div>
          <h4 className="mt-12 text-2xl  decoration-amber-600">À Savoir</h4>
          <UnderlineSmall/>
          <div className="flex flex-col items-start mb-10">
            <CampsItem icon={<>🧰</>} text={<>Équipement équestre fourni</>} />
            <CampsItem icon={<>🐴</>} text={<>Possibilité de monter différents chevaux</>} />
          </div>

        </div>
        <div className="w-full portrait:w-4"></div>
      </Element>
      <Element name="section-02" className="w-screen min-h-screen-more bg-stone-100 relative snap-start p-4 flex flex-row">
        <div className="w-36 portrait:w-4"></div>
        <div className="flex flex-col grow mt-20">
          <div className="text-3xl lg:text-4xl font-extrabold">Formations Offertes</div>
          <div className="text-md lg:text-2xl font-light pt-2">Accès à plusieurs certifications reconnues par Cheval Québec et Canada Équestre.</div>
          <Underline/>
          <div className="flex flex-col flex-wrap lg:flex-row landscape:flex-row grow justify-between items-center">
            <CourseFormation
              img={images.course_book_01}
              title={"Le P'tit trot"}
              description={"Programme d'apprentissage destiné aux enfants de 5 ans et plus."}
              link="https://www.cheval.quebec/ptittrot.html" />
            <CourseFormation
              img={images.course_book_02}
              title={"Équitation western"}
              description={"Programme d'apprentissage composé de quatre niveaux."}
              link="https://www.cheval.quebec/apprentissage-equitation-western.html" />
            <CourseFormation
              img={images.course_book_05}
              title={"Devenir Instructeur"}
              description={"Formation pour devenir instructeur certifié par Canada Équestre."}
              link="https://www.cheval.quebec/devenir-instructeur.html" />
            <CourseFormation
              img={images.course_book_04}
              title={"Entraînement de base"}
              description={"Formation pour devenir instructeur certifié par Canada Équestre."}
              link="https://www.youtube.com/watch?app=desktop&v=mAcqzGJMkdQ&ab_channel=ChevalQu%C3%A9bec" />
          </div>
        </div>
        <div className="w-36 portrait:w-4"></div>
      </Element>

      <div className="flex flex-col bg-stone-700 py-5 lg:h-64 items-center justify-center text-md lg:text-2xl text-stone-100 font-light text-center">
        <div>Instructeurs et entraîneurs certifiés sur place. <br />Certifiés par cheval Québec et Canada Équestre</div>

        <div className="flex flex-row justify-evenly portrait:justify-between mt-10 px-5 w-screen">
          <img src={images.course_association_0.src} alt={images.course_association_0.alt} className="w-12 lg:w-24 object-contain" />
          <img src={images.course_association_1.src} alt={images.course_association_1.alt} className="w-10 lg:w-20 object-contain" />
          <img src={images.course_association_2.src} alt={images.course_association_2.alt} className="w-24 lg:w-48 object-contain" />
        </div>
      </div>

      <Element name="section-03" className="w-screen min-h-screen bg-white text-stone-700 relative snap-start p-4 flex flex-row">
        <div className="w-full portrait:w-4"></div>
        <div className="flex flex-col grow mt-20">
          <div className="text-3xl lg:text-4xl font-extrabold">Tarifs - 2025</div>
          <div className="text-md lg:text-2xl font-light pt-2">Prix pour une séance d'une heure</div>
          <Underline/>
          <div className="flex flex-col lg:flex-row landscape:flex-row grow justify-between">
            <CourseThumbnail
              img={images.course_price_thumbail_01}
              label={
                <div className="flex flex-col items-start font-normal mt-4">
                  <div className=" font-bold">PRIVÉ</div>
                  <div>65$</div>
                </div>
              } />
            <CourseThumbnail
              img={images.course_price_thumbail_02}
              label={
                <div className="flex flex-col items-start font-normal mt-4">
                  <div className=" font-bold">SEMI-PRIVÉ</div>
                  <div>50$ / personne</div>
                  <div className="italic text-sm">2 cavaliers, 2 montures</div>
                </div>
              } />
            <CourseThumbnail
              img={images.course_price_thumbail_03}
              label={
                <div className="flex flex-col items-start font-normal mt-4">
                  <div className=" font-bold">GROUPE</div>
                  <div>40$ / personne</div>
                  <div className="italic text-sm">3+ cavaliers, 3+ montures</div>
                </div>
              } />
          </div>
        </div>
        <div className="w-full portrait:w-4"></div>
      </Element>


    </div>
  </>
}
